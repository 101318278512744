import { QuestionMarkRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import Asset from 'components/assets/asset';
import BackDropInfo from 'components/backdrops/back-drop-info';
import Logo from 'components/brands/logo';
import { motion, useAnimation } from 'framer-motion';
import useDate from 'hooks/use-date';
import Cookies from 'js-cookie';
import { lighten } from 'polished';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SwipeableViews, { OnChangeIndexCallback } from 'react-swipeable-views';
import { useRouter } from 'routes/hooks/use-router';

const CustomPaper = motion(Paper);
const CustomIconButton = motion(IconButton);

const paperVariants = {
  hidden: {
    y: 2000,
  },
  visible: {
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0.25,
    },
  },
};

function KeyInformation({
  children,
  path,
}: {
  children: string;
  path?: string;
}) {
  const router = useRouter();
  const theme = useTheme();

  const handleClick = () => {
    if (!path) return;
    router.push(path);
  };

  return (
    <span
      style={{
        fontWeight: 600,
        ...(path && {
          color: lighten(0.1, theme.palette.secondary.light),
          cursor: 'pointer',
        }),
      }}
      onClick={handleClick}
    >
      {children}
    </span>
  );
}

type Section_t = {
  title: string | React.ReactNode;
  description: React.ReactNode;
  asset: {
    pathname: string;
    minWidth: number;
  };
};

function Section({ title, description, asset }: Section_t) {
  return (
    <Grid container sx={{ display: 'flex', gap: 4 }} direction="row">
      <Grid item sm={7} pr={3}>
        {typeof title === 'string' ? (
          <Typography variant="h3">{title}</Typography>
        ) : (
          title
        )}
        <Typography variant="body1" pt={1}>
          {description}
        </Typography>
      </Grid>
      <Grid item sm sx={{ position: 'relative' }}>
        <Asset
          pathname={asset.pathname}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            minWidth: asset.minWidth,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default function Tuto() {
  const [open, setOpen] = useState<boolean>(false);
  const controls = useAnimation();

  const { pathname } = useLocation();
  const date = useDate();

  // Cookie for the first login (for tuto)
  useEffect(() => {
    const tuto = Cookies.get('tuto');
    if (tuto === undefined) {
      setOpen(true);
      Cookies.set('tuto', 'done', {
        expires: date.dayjs().add(100, 'year').toDate(),
      });
    }
  }, []);

  useEffect(() => {
    open && close();
  }, [pathname]);

  const close = () => {
    setIndex(0);
    setOpen(false);
    controls.start({
      scale: [1, 1.3, 1],
      transition: {
        ease: 'easeInOut',
        duration: 0.4,
        repeat: 1,
      },
    });
  };

  const [index, setIndex] = useState<number>(0);
  const handleChangeIndex: OnChangeIndexCallback = (index: number) =>
    setIndex(index);

  const handleClick = () => {
    if (index === sections.length - 1) close();
    else handleChangeIndex(index + 1, index);
  };

  return (
    <>
      <CustomIconButton
        animate={controls}
        color="inherit"
        sx={{ mr: 1 }}
        onClick={() => setOpen(true)}
      >
        <QuestionMarkRounded fontSize="small" />
      </CustomIconButton>
      <BackDropInfo open={open} close={close}>
        <CustomPaper
          variants={paperVariants}
          initial="hidden"
          animate="visible"
          sx={{
            width: '1000px',
            height: '450px',
            borderRadius: 5,
            border: (theme) =>
              `1px solid ${lighten(0.05, theme.palette.background.paper)}`,
            p: 8,
            pb: 5,
            display: 'flex',
            flexDirection: 'column',
            '& .react-swipeable-view-container': { height: '100%' },
          }}
        >
          <SwipeableViews
            index={index}
            onChangeIndex={handleChangeIndex}
            style={{ flexGrow: 1 }}
            animateTransitions={false}
          >
            {sections.map((section, key) => (
              <Section {...section} key={key} />
            ))}
          </SwipeableViews>
          <Box width={'100%'} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleClick}
              {...(index === sections.length - 1 && {
                variant: 'contained',
                color: 'secondary',
              })}
            >
              {index === sections.length - 1 ? "J'ai tout compris" : 'Suivant'}
            </Button>
          </Box>
        </CustomPaper>
      </BackDropInfo>
    </>
  );
}

// Section data
const sections: Section_t[] = [
  {
    title: (
      <Box display={'flex'} alignItems="center">
        <Typography variant="h3">Bienvenue sur</Typography>
        <Logo widthPx={140} clickable={false} style={{ marginLeft: 10 }} />
      </Box>
    ),
    description: (
      <>
        L’application conçue pour conserver et visualiser des évolutions{' '}
        <KeyInformation>avant/après</KeyInformation>. Créez votre premier{' '}
        <KeyInformation>trsform</KeyInformation>, ajoutez du contenu, et
        partagez-le avec vos amis...
      </>
    ),
    asset: {
      pathname: '/images/view-devices.png',
      minWidth: 400,
    },
  },
  {
    title: 'Créez votre premier trsform',
    description: (
      <>
        Créez votre premier suivi, appelé "
        <KeyInformation>trsform</KeyInformation>
        ", en vous rendant dans{' '}
        <KeyInformation path="/user/gallery/my-trsforms">
          votre galerie
        </KeyInformation>
        . Remplissez ensuite les informations nécessaires, comme le titre, la
        date de début, et la durée en jours pendant laquelle vous pourrez
        ajouter du contenu à partir de cette date.
      </>
    ),
    asset: {
      pathname: '/images/form-create-trsform.png',
      minWidth: 280,
    },
  },
  {
    title: 'Remplissez votre trsform',
    description: (
      <>
        Une fois créé, vous pourrez enrichir votre{' '}
        <KeyInformation>trsform</KeyInformation> en y intégrant des{' '}
        <KeyInformation>images</KeyInformation>, des{' '}
        <KeyInformation>descriptions</KeyInformation> détaillées et des{' '}
        <KeyInformation>graphiques</KeyInformation> pour suivre l'évolution.
        Chaque ajout vous permettra de mieux visualiser les étapes de votre
        évolutionet de créer un suivi complet.
      </>
    ),
    asset: {
      pathname: '/common/add-information.svg',
      minWidth: 300,
    },
  },
  {
    title: 'Débloquez des récompenses',
    description: (
      <>
        Impliquez-vous pleinement dans votre{' '}
        <KeyInformation>trsform</KeyInformation> et gagnez des jours en
        atteignant différents <KeyInformation>achievements</KeyInformation>.
        Chaque objectif accompli vous rapproche de nouvelles distinctions, tout
        en vous motivant à poursuivre votre transformation.
      </>
    ),
    asset: {
      pathname: '/images/achievement-view.png',
      minWidth: 300,
    },
  },
  {
    title: 'Partagez à vos proches',
    description: (
      <>
        Partagez votre <KeyInformation>trsform</KeyInformation> avec vos proches
        afin qu’ils puissent suivre en détail son évolution.
      </>
    ),
    asset: {
      pathname: '/common/share.svg',
      minWidth: 300,
    },
  },
  {
    title: 'Ajoutez des jours',
    description: (
      <>
        Lorsque votre <KeyInformation>trsform</KeyInformation> arrive à son
        terme, vous aurez la possibilité d'ajouter des jours supplémentaires
        pour continuer à le remplir. Si votre compte n’en dispose plus, vous
        pourrez en acheter directement dans la boutique, où des promotions sont
        régulièrement proposées. Pensez également à profiter de l'offre de{' '}
        <KeyInformation path="/user/profile/referral">
          parrainage
        </KeyInformation>{' '}
        : elle vous permet de bénéficier d'une{' '}
        <KeyInformation>réduction de 60 %</KeyInformation> sur votre prochain
        achat.
      </>
    ),
    asset: {
      pathname: '/common/paiement-success.svg',
      minWidth: 300,
    },
  },
];
