import { Backdrop, Box, ClickAwayListener } from '@mui/material';
type Props = {
  children: JSX.Element;
  open: boolean;
  close: () => void;
};

export default function BackDropInfo({ children, open, close }: Props) {
  return (
    <>
      {open && (
        <Backdrop open={open}>
          <ClickAwayListener onClickAway={close}>
            <Box>{children}</Box>
          </ClickAwayListener>
        </Backdrop>
      )}
    </>
  );
}
