import { Box, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { waiter } from 'libs/time';

export default function Promotion() {
  const TIME_TRANSITION_BOX = 0.4;
  const TIME_WAITING_DISPLAY_MS = 5000;
  const TIME_SHOW_BOX_MS = 10000;

  const PROBA_DISPLAY = 1 / 10;

  const CODE = 'LANCEMENT20';

  const [display, setDisplay] = useState<boolean>(false);
  useEffect(() => {
    if (Math.random() <= PROBA_DISPLAY) {
      (async () => {
        await waiter(TIME_WAITING_DISPLAY_MS);
        setDisplay(true);
        await waiter(TIME_SHOW_BOX_MS);
        setDisplay(false);
      })();
    }
  }, []);

  return (
    <AnimatePresence mode="wait">
      {display && (
        <Box
          component={motion.div}
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ duration: TIME_TRANSITION_BOX }}
          sx={{
            width: '100%',
            background: (theme) => theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
            zIndex: 100000,
          }}
        >
          <Typography
            variant="body2"
            sx={{ textTransform: 'uppercase' }}
            py={0.5}
          >
            Pour le lancement de l'application, profitez de -20% sur tous les
            packs avec le code{' '}
            <Typography variant="subtitle2" component="span">
              {CODE}
            </Typography>{' '}
            !
          </Typography>
        </Box>
      )}
    </AnimatePresence>
  );
}
