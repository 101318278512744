import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Data_referralRewardGranted_t } from 'ts/types/notification.type';
import useNotification, { Props_NotificationItem } from '../use-notification';
import MatterText from './common/matter-text';
import PrimaryContainer from './common/primary-container';
import Asset from 'components/assets/asset';

export default function NotifReferralRewardGranted({
  notification,
}: Props_NotificationItem) {
  const data = notification.data as Data_referralRewardGranted_t;
  const theme = useTheme();
  const notificationHook = useNotification();

  return (
    <ListItem
      sx={{
        ...notificationHook.style.styleListItem({
          viewed: Boolean(notification.viewedOn),
        }),
      }}
    >
      <ListItemAvatar
        sx={{
          height: '100%',
          mt: 0.5,
        }}
      >
        <Asset pathname="/notification/gift.svg" widthPx={35} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <PrimaryContainer createdAt={notification.createdAt}>
            <Typography variant="body2" color={theme.palette.grey[400]}>
              Votre parrainage avec{' '}
              <MatterText text={`${data.firstName} ${data.lastName}`} /> vous a
              permis de gagner <MatterText text={`${data.daysAwarded} jours`} />{' '}
              !
            </Typography>
          </PrimaryContainer>
        }
      />
    </ListItem>
  );
}
