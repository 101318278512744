import {
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Asset from 'components/assets/asset';
import Copyright from 'components/brands/copyright';
import Logo from 'components/brands/logo';
import { URL_INSTAGRAM } from 'libs/link';
import { lighten } from 'polished';

type Link_t = {
  name: string;
  href: string;
};

type Props_LayoutSection = {
  title: string;
  children: React.ReactNode;
};

function LayoutSection({ title, children }: Props_LayoutSection) {
  return (
    <Box>
      <Typography
        variant="body2"
        color="grey.700"
        textTransform="uppercase"
        textAlign="inherit"
        mb={2}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
}

type Props_SectionLink = {
  links: Link_t[];
};

function SectionLink({ links }: Props_SectionLink) {
  return (
    <Stack gap={1}>
      {links.map((link, key) => (
        <Link
          href={link.href}
          color="grey.300"
          underline="hover"
          variant="body2"
          key={key}
        >
          {link.name}
        </Link>
      ))}
    </Stack>
  );
}

type Logo_t = {
  pathname: string;
  href: string;
};

function SectionNetwork() {
  const WIDTH_PX = 23;
  const logos: Logo_t[] = [
    {
      pathname: '/logo/instagram-white.svg',
      href: URL_INSTAGRAM,
    },
    {
      pathname: '/logo/tiktok-white.svg',
      href: 'https://www.tiktok.com',
    },
  ];

  const handleClick = (href: string) => () => {
    window.location.href = href;
  };

  return (
    <Stack
      gap={1}
      direction="row"
      justifyContent={{ xs: 'center', md: 'unset' }}
    >
      {logos.map((logo, key) => (
        <Box
          key={key}
          component="span"
          onClick={handleClick(logo.href)}
          sx={{
            cursor: 'pointer',
            ':hover': {
              opacity: 0.8,
            },
          }}
        >
          <Asset pathname={logo.pathname} widthPx={WIDTH_PX} alt="" />
        </Box>
      ))}
    </Stack>
  );
}

export default function Footer() {
  const theme = useTheme();
  const upXl = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={10}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        borderTop: (theme) =>
          `1px solid ${lighten(0.08, theme.palette.background.paper)}}`,
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'center', md: 'unset' }}
      >
        <Logo widthPx={upXl ? 130 : 100} />
        <Stack
          gap={{ xs: 8, md: 10 }}
          ml={{ xs: 0, md: 15 }}
          mt={{ xs: 7, md: 0 }}
          direction={{ xs: 'column', md: 'row' }}
          textAlign={{ xs: 'center', md: 'unset' }}
        >
          {sections.map((section, key) => (
            <LayoutSection title={section.title} key={key}>
              {section.section}
            </LayoutSection>
          ))}
        </Stack>
      </Box>
      <Copyright sx={{ mt: 14, color: 'grey.600' }} />
    </Box>
  );
}

// Sections
type Section_t = {
  title: string;
  section: React.ReactNode;
};

const sections: Section_t[] = [
  {
    title: 'Accueil',
    section: (
      <SectionLink
        links={[
          { name: 'Home', href: '/' },
          { name: 'À propos', href: '/' },
          { name: 'Contact', href: '/' },
        ]}
      />
    ),
  },
  {
    title: 'Application',
    section: (
      <SectionLink
        links={[
          { name: 'Mon compte', href: '/user' },
          { name: "S'inscrire", href: '/auth/signup' },
          { name: 'Se connecter', href: '/auth/login' },
          { name: 'Mot de passe oublié?', href: '/auth/forget-password' },
        ]}
      />
    ),
  },
  {
    title: 'Conditions et politiques',
    section: (
      <SectionLink
        links={[
          { name: 'Mentions légales', href: '/terms/legal-notice' },
          {
            name: 'Conditions générales d’utilisation',
            href: '/terms/terms-of-service',
          },
          { name: 'Conditions générales de vente', href: '/terms/sales-terms' },
          {
            name: 'Politique de confidentialité',
            href: '/terms/privacy-policy',
          },
          { name: 'Politique de cookies', href: '/terms/cookie-policy' },
        ]}
      />
    ),
  },
  {
    title: 'Réseaux',
    section: <SectionNetwork />,
  },
];
