import React from 'react';
import { EntityTypes } from 'config/notification.config';
import NotifCreateShare from './notification-type/notif-create-share';
import { Notification_t } from 'ts/types/notification.type';
import { Request } from 'api/request';
import NotifResponseShare from './notification-type/notif-response-share';
import NotifDeleteShare from './notification-type/notif-delete-share';
import NotifNewAchievement from './notification-type/notif-new-achievement';
import NotifNewMedia from './notification-type/notif-new-media';
import NotifNewReferral from './notification-type/notif-new-referral';
import NotifReferralRewardGranted from './notification-type/notif-referral-reward-granted';

export default function useNotification() {
  const notification = {
    getNotificationType: getNotificationType,
    getNotifications: getNotifications,
    setNotificationViewed: setNotificationViewed,
    style: {
      styleListItem: styleListItem,
    },
  };
  return notification;
}

type Props_styleListItem = {
  viewed: boolean;
};
const styleListItem = ({ viewed }: Props_styleListItem) => ({
  py: 1.5,
  px: 2.5,
  mt: '1px',
  alignItems: 'flex-start',
  ...(!viewed && {
    bgcolor: 'action.selected',
  }),
});

export type Props_NotificationItem = {
  setNotifications: React.Dispatch<
    React.SetStateAction<Notification_t[] | undefined>
  >;
  notifications: Notification_t[];
  notification: Notification_t;
  handleClose: () => void;
};

function getNotificationType(
  entityType: EntityTypes,
): React.FC<Props_NotificationItem> | undefined {
  if (entityType === EntityTypes.createShare) return NotifCreateShare;
  if (entityType === EntityTypes.responseShare) return NotifResponseShare;
  if (entityType === EntityTypes.deleteShare) return NotifDeleteShare;
  if (entityType === EntityTypes.newAchievement) return NotifNewAchievement;
  if (entityType === EntityTypes.newMedia) return NotifNewMedia;
  if (entityType === EntityTypes.newReferral) return NotifNewReferral;
  if (entityType === EntityTypes.referralRewardGranted) return NotifReferralRewardGranted;
}

type Props_getNotifications = {
  start: number;
  end: number;
};
async function getNotifications({ start, end }: Props_getNotifications) {
  try {
    const response = await Request.send({
      url: `notification?start=${start}&end=${end}`,
      method: 'GET',
    });

    if (response.isSuccess()) {
      const notifications: Notification_t[] = await Promise.all(
        response.data.notifications.map(
          async (notification: Notification_t) => {
            return {
              notificationId: notification.notificationId,
              viewedOn: notification.viewedOn,
              entityType: notification.entityType,
              createdAt: notification.createdAt,
              data: notification.data,
            };
          },
        ),
      );

      return notifications;
    }
  } catch (error) {
    console.error(error);
  }
}

type Props_setNotificationView = {
  notifications: Notification_t[];
  notificationId?: number;
};

async function setNotificationViewed({
  notifications,
  notificationId,
}: Props_setNotificationView): Promise<Notification_t[]> {
  const fetchSetView = async () => {
    try {
      const body = {
        notification: {
          notificationId: notificationId,
        },
      };
      await Request.send({
        url: `notification/viewed`,
        method: 'PUT',
        body: body,
      });
    } catch (error) {
      console.error(error);
    }
  };
  fetchSetView();
  let updatedNotifications: Notification_t[];
  if (notificationId) {
    const index = notifications.findIndex(
      (notification) => notification.notificationId === notificationId,
    );
    updatedNotifications = notifications.slice(0);
    updatedNotifications[index] = {
      ...updatedNotifications[index],
      viewedOn: new Date().toISOString(),
    };
  } else {
    updatedNotifications = notifications.map((notification) => ({
      ...notification,
      viewedOn: new Date().toISOString(),
    }));
  }
  return updatedNotifications;
}
