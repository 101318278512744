import { Helmet } from 'react-helmet-async';
import DashboardContext from './context';
import NavBar from './nav-bar';
import { Box, Fade } from '@mui/material';
import AppBar from './app-bar';
// import Copyright from 'components/brands/copyright';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

// ----------------------------------------------------------------------

type Props_Userlayout = {
  children: React.ReactNode;
};

export default function Userlayout({ children }: Props_Userlayout) {
  const { pathname } = useLocation();
  const mainContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTo(0, 0); // Scroll the container to top
    }
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Trsform</title>
      </Helmet>

      <DashboardContext>
        <Fade in timeout={600}>
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
            }}
          >
            <NavBar />
            <Box
              ref={mainContainerRef}
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                }}
              >
                <AppBar />
                <Box
                  sx={{
                    flexGrow: 1,
                    flexDirection: 'column',
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingBottom: 2,
                    display: 'flex',
                  }}
                >
                  {children}
                </Box>
              </Box>
              {/* <Copyright
                sx={{
                  pt: 5,
                  pb: 4,
                  opacity: 0.5,
                }}
              /> */}
            </Box>
          </Box>
        </Fade>
      </DashboardContext>
    </>
  );
}
