import { Suspense, lazy } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';
import AuthentificationLayout from 'layouts/authentification';
import Userlayout from 'layouts/user';
import HomeLayout from 'layouts/home';

export const DashboardPage = lazy(() => import('../pages/dashboard'));
export const ForgetPasswordPage = lazy(
  () => import('../pages/forget-password'),
);
export const HomePage = lazy(() => import('../pages/home'));
export const TermsPage = lazy(() => import('../pages/terms'));
export const GalleryPage = lazy(() => import('../pages/gallery'));
export const ShopPage = lazy(() => import('../pages/shop'));
export const ProfilePage = lazy(() => import('../pages/profile'));
export const LoginPage = lazy(() => import('../pages/login'));
export const SignupPage = lazy(() => import('../pages/signup'));
export const ValidEmailPage = lazy(() => import('../pages/valid-email'));
export const MobileRedirect = lazy(() => import('../pages/mobile-redirect'));
// export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '*',
      element: (
        <HomeLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </HomeLayout>
      ),
      children: [
        {
          element: <HomePage />,
          index: true,
        },
        {
          element: <TermsPage />,
          path: 'terms/*',
        },
      ],
    },
    {
      path: 'user/*',
      element: (
        <Userlayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </Userlayout>
      ),
      children: [
        { element: <DashboardPage />, index: true },
        { path: 'gallery/*', element: <GalleryPage /> },
        { path: 'shop/*', element: <ShopPage /> },
        {
          path: 'profile/*',
          element: <ProfilePage />,
        },
      ],
    },
    {
      path: 'auth/*',
      element: (
        <AuthentificationLayout>
          <Outlet />
        </AuthentificationLayout>
      ),
      children: [
        { path: 'login', element: <LoginPage /> },
        { path: 'signup', element: <SignupPage /> },
        { path: 'forget-password/*', element: <ForgetPasswordPage /> },
      ],
    },
    {
      path: 'valid-email/:token',
      element: <ValidEmailPage />,
    },
    {
      path: 'mobile-redirect',
      element: <MobileRedirect />,
    },
  ]);

  return routes;
}
