import { AppBar as AppBarMui, Box, Toolbar } from '@mui/material';
import Portfolio from './common/portfolio';
import Notification from './common/notification';
import Menu from './common/menu';
import Path from './common/path';
import Tuto from './common/tuto';

export default function AppBar() {
  return (
    <AppBarMui
      position="static"
      sx={{
        paddingTop: 1.5,
        background: 'unset',
        boxShadow: 'unset',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Path />
        <Box display="flex" alignItems="center" sx={{ m: 0 }}>
          <Tuto />
          <Portfolio />
          <Notification />
          <Menu />
        </Box>
      </Toolbar>
    </AppBarMui>
  );
}
