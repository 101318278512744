import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Data_newReferral_t } from 'ts/types/notification.type';
import useNotification, { Props_NotificationItem } from '../use-notification';
import MatterText from './common/matter-text';
import PrimaryContainer from './common/primary-container';
import Asset from 'components/assets/asset';
import { useRouter } from 'routes/hooks/use-router';

export default function NotifNewReferral({
  notification,
}: Props_NotificationItem) {
  const data = notification.data as Data_newReferral_t;
  const theme = useTheme();
  const notificationHook = useNotification();
  const router = useRouter();

  return (
    <ListItemButton
      sx={{
        ...notificationHook.style.styleListItem({
          viewed: Boolean(notification.viewedOn),
        }),
      }}
      onClick={() => router.push('/user/profile/referral')}
    >
      <ListItemAvatar
        sx={{
          height: '100%',
          mt: 0.5,
        }}
      >
        <Asset pathname="/notification/friends.svg" widthPx={35} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <PrimaryContainer createdAt={notification.createdAt}>
            <Typography variant="body2" color={theme.palette.grey[400]}>
              <MatterText text={`${data.firstName} ${data.lastName}`} /> vous a
              désigné comme son parrain ! Lors de son prochain achat, vous
              recevrez une récompense.
            </Typography>
          </PrimaryContainer>
        }
      />
    </ListItemButton>
  );
}
