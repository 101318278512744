import { useScrollToTop } from './hooks/use-scroll-to-top';
import ThemeProvider from './theme/index';
import Router from './routes/sections';
import LoaderTop from './components/loaders/loader-top';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import GlobalLayout from './layouts/global';
import { useGtm } from 'hooks/use-gtm';
import { useMobile } from 'hooks/use-mobile';

// ----------------------------------------------------------------------
export default function App() {
  useMobile();
  useScrollToTop();
  useGtm();

  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <LoaderTop>
            <GlobalLayout>
              <Router />
            </GlobalLayout>
          </LoaderTop>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
