import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRouter } from 'routes/hooks/use-router';

// ----------------------------------------------------------------------

const firstPathForbiddenMobileList = ['auth', 'user'];

export function useMobile() {
  const { pathname } = useLocation();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const firstPath = pathname.split('/')[1];
    if (isMobile && firstPathForbiddenMobileList.includes(firstPath))
      router.push('/mobile-redirect');
  }, []);

  return null;
}
